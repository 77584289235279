<template>
  <div class="category">
    <h1 class="sm category-title">{{'Kategori ' + (categoryIndex + 1) + ': ' + title}}</h1>
    <p class="text-size-xl category-description">{{description}}</p>
    <p class="category-questions">{{questionsTotal}} spørgsmål</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    categoryIndex: {
      type: Number,
      default: 0
    },
    questionsTotal: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="stylus" scoped>
.category
  flex-grow 1
  height 100%
  display flex
  flex-flow column
  align-items center
  text-align center

.category-title
.category-description
  color white
  display flex
  flex-flow column
  justify-content flex-end

.category-title
  flex: 1 1 auto
  justify-content flex-end

.category-description
  flex: 0 1 auto
  justify-content flex-start

.category-questions
  flex: 1 1 auto
  justify-content flex-start
</style>
