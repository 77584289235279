<template>
  <div class="container">
    <div
      v-for="(step, index) in steps" :key="index"
      class="step"
      :class="{ visible: index <= currentStepIndex }"
      :style="`background-color: ${step.color}`"
    >
    </div>
  </div>
</template>

<script>
export default {
  name: 'SurveyProgressIndicator',
  props: {
    steps: {
      type: Array,
      required: true
    },
    currentStepIndex: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="stylus" scoped>
.container
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 36px auto 0;
  display: flex;
  background rgb(253,252,248);
  height: 16px;
  width: calc(100% - 12rem);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 3px;

.container > *
  height: 10px;
  flex-grow: 1;
  opacity: 0;
  &.visible
    opacity: 1;

.container > *:first-child
  border-radius: 5px 0 0 5px;

.container > *:last-child
  border-radius: 0 5px 5px 0;
</style>
