<template>
  <div class="question">
    <h1 class="sm question-title" :style="`color: ${color};`">Spørgsmål {{questionIndex + 1}}/{{questionsTotal}}</h1>
    <div class="question-content">
      <p class="question-description text-size-xl">{{description}}</p>
      <survey-question-rating
        :value="value"
        @answerChanged="answerChanged"
        :color="color"
      ></survey-question-rating>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
import SurveyQuestionRating from './SurveyQuestionRating.vue'
import anime from 'animejs/lib/anime.es.js'

export default {
  components: { SurveyQuestionRating },
  props: {
    value: {
      type: String,
      default: null
    },
    questionId: {
      type: String,
      default: null
    },
    questionIndex: {
      type: Number,
      default: 0
    },
    questionsTotal: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: '#989898'
    }
  },
  emits: ['answerChanged'],
  data () {
    return {
      description: ''
    }
  },
  methods: {
    answerChanged (value) {
      this.$emit('answerChanged', value)
    }
  },
  async mounted () {
    if (this.$store.state.questions.data[this.questionId]) {
      this.description = this.$store.state.questions.data[this.questionId].description
    } else {
      const questionRef = firebase.firestore().collection('questions').doc(this.questionId)
      const snapshot = await questionRef.get()
      this.description = snapshot.data().description
    }
    this.$nextTick(() => {
      anime.remove('.question-description')
      const el = document.querySelector('.question-description')
      anime({
        targets: el,
        opacity: 1,
        easing: 'linear',
        duration: 700
      })
    })
  }
}
</script>

<style lang="stylus" scoped>
.question
  flex-grow 1
  height 100%
  display flex
  flex-flow column
  align-items center
  text-align center

.question-title
.question-content
  display flex
  flex-flow column
  justify-content flex-end

.question-title
  flex: 1 1 auto
  justify-content flex-end

.question-description
  opacity 0

.question-content
  flex: 2 1 auto
  justify-content flex-start
</style>
