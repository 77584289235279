<template>
  <div id="content-area" v-if="!loading">
    <div v-if="!error">
      <div v-if="!showLandingPage">
        <survey-progress-indicator
          :currentStepIndex="currentStepIndex"
          :steps="steps"
        ></survey-progress-indicator>
        <survey-question
          v-if="steps.length > 0 && currentStep.type === 'question'"
          :key="currentStep.id"
          :color="currentStep.color"
          :questionId="currentStep.id"
          :questionIndex="currentStep.questionIndex"
          :questionsTotal="categories[currentStep.categoryIndex].questions.length"
          :value="responseData.answers[currentStep.id]"
          @answerChanged="saveAnswer"
        />
        <survey-category
          v-if="steps.length > 0 && currentStep.type === 'category'"
          :description="currentStep.description"
          :title="currentStep.title"
          :categoryIndex="currentStep.categoryIndex"
          :questionsTotal="categories[currentStep.categoryIndex].questions.length"
        />
        <nav style="justify-content: center;">
          <base-ui-button v-if="currentStepIndex === 0 && !id" @clicked="$router.push('/dashboard')">Luk</base-ui-button>
          <base-ui-button v-if="currentStepIndex > 0" @clicked="prev" :disabled="freeze">Tilbage</base-ui-button>
          <base-ui-button v-if="currentStepIndex < (steps.length - 1)" @clicked="next" :disabled="disableNext" :bgColor="'white'" :color="'#0A0A0A'">{{ currentStep.type === 'question' ? 'Videre' : 'Start kategori' }}</base-ui-button>
          <base-ui-button v-if="currentStepIndex === (steps.length - 1)" @clicked="send" :primary="completed">Afslut</base-ui-button>
        </nav>
      </div>
      <div v-else>
        <a href="https://coreculture.dk/home" class="text-size-sm">Core Culture</a>
        <h1>Velkommen til spørgeskemaet om arbejdsfællesskab</h1>
        <p>Formålet med spørgeskemaet er at måle, hvordan I opfatter arbejdsfællesskabet i virksomheden. Din besvarelse hjælper ledelsen med at vurdere, hvilke indsatser der vil styrke arbejdsfællesskabet.</p>
        <p><b>Det tager højst 5 minutter</b> at besvare spørgeskemaet. I spørgeskemaet bliver du bedt om at tage stilling til en række spørgsmål. Hvis du er i tvivl om, hvor du skal sætte dit kryds, skal du sætte det der, hvor det giver mest mening.</p>
        <p>God fornøjelse!</p>
        <nav style="justify-content: center;">
          <base-ui-button @clicked="land" primary>Svar på spørgeskemaet</base-ui-button>
        </nav>
      </div>
    </div>
    <div v-if="error">
      <h2>{{ error }}</h2>
    </div>
  </div>
</template>

<script>
import SurveyQuestion from '@/components/survey/SurveyQuestion'
import SurveyCategory from '@/components/survey/SurveyCategory'
import BaseUiButton from '../components/shared/BaseUiButton.vue'
import { isValueInObject } from '../sharedFunctions/helpers.js'
import anime from 'animejs/lib/anime.es.js'
import SurveyProgressIndicator from '../components/survey/SurveyProgressIndicator.vue'

export default {
  components: {
    SurveyQuestion,
    SurveyCategory,
    BaseUiButton,
    SurveyProgressIndicator
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    scope: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: true,
      error: '',
      showLandingPage: true,
      steps: [],
      currentStepIndex: 0,
      freeze: false
    }
  },
  computed: {
    surveyData () {
      return this.$store.state.survey.data
    },
    responseData () {
      return this.$store.state.response.data
    },
    categories () {
      return this.surveyData ? this.surveyData.categories : []
    },
    currentStep () {
      return this.steps[this.currentStepIndex]
    },
    currentStepSavedAnswer () {
      return this.$store.state.response.data.answers[this.currentStep.id]
    },
    isLastStep () {
      return this.currentStepIndex === (this.steps.length - 1)
    },
    disableNext () {
      return this.currentStepSavedAnswer === null || this.freeze
    },
    completed () {
      return this.responseData ? this.responseData.completed : null
    }
  },
  async mounted () {
    this.$emit('update-background', 'rgb(253,252,248)')
    console.log('survey flow mounted')
    await this.$store.dispatch('survey/initSurvey', { surveyId: this.id, loggedIn: false })
    const template = await this.$store.dispatch('survey/getTemplate')
    // if survey is not newest show error
    if (this.$store.state.survey.data.version !== template.version) {
      this.error = 'Denne måling kan ikke længere besvares'
    } else {
      this.$store.dispatch('response/clear')
      await this.$store.dispatch('response/create', this.$store.state.survey)
      if (this.scope) {
        this.$store.commit('response/setScope', this.scope)
      }
    }
    if (!this.id) this.land()
    this.loading = false
  },
  methods: {
    land () {
      this.initSteps(this.categories)
      this.showLandingPage = false
    },
    initSteps (categories) {
      categories.forEach((cat, i) => {
        if (cat.showCategoryStep) {
          this.steps.push({ type: 'category', categoryIndex: i, title: cat.title, description: cat.description, color: cat.color })
        }
        cat.questions.forEach((q, j) => {
          this.steps.push(
            {
              type: 'question',
              categoryIndex: i,
              questionIndex: j,
              id: q,
              color: cat.color
            }
          )
        })
      })
      const isCat = this.currentStep.type === 'category'
      const bgColor = isCat ? this.currentStep.color : 'rgb(253,252,248)'
      this.$emit('update-background', bgColor)
    },
    prev () {
      if (this.currentStepIndex > 0) {
        this.currentStepIndex--
        const isCat = this.currentStep.type === 'category'
        const bgColor = isCat ? this.currentStep.color : 'rgb(253,252,248)'
        this.$emit('update-background', bgColor)
      }
    },
    next () {
      if (this.isLastStep) {
        this.send()
      } else {
        this.currentStepIndex++
        const isCat = this.currentStep.type === 'category'
        const bgColor = isCat ? this.currentStep.color : 'rgb(253,252,248)'
        this.$emit('update-background', bgColor)
      }
    },
    async saveResponse (anonymous) {
      // const loggedIn = this.$store.state.user.loggedIn
      if (this.id && this.scope) {
        this.$store.dispatch('response/save', { surveyId: this.id, responseId: null, anonymous: anonymous, scope: this.scope })
      }
    },
    async send () {
      const loggedIn = this.$store.state.user.loggedIn
      if (this.id && loggedIn) {
        if (window.confirm(`Vi kan se at du er logget ind som ${this.$store.state.user.data.email}. Er du sikker på, at du vil deltage i undersøgelsen? Hvis du klikker på ok, vil dit svar indgå i undersøgelsen og som en anonym besvarelse.`)) {
          this.saveResponse(true)
          this.$router.push('/kvittering/alm')
        }
      } else {
        this.saveResponse(true)
        if (this.id) {
          this.$router.push('/kvittering/anonym')
        } else {
          this.$router.push('/kvittering/demo')
        }
      }
    },
    delay (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async saveAnswer (val) {
      const initialAnswer = this.currentStepSavedAnswer === null
      this.$store.commit('response/setAnswerValue', {
        id: this.currentStep.id,
        value: val
      })
      const completed = !isValueInObject(this.$store.state.response.data.answers, null)
      if (completed) {
        this.$store.commit('response/setCompleted', true)
      }
      if (initialAnswer && (this.currentStepIndex < (this.steps.length - 1))) {
        this.freeze = true
        await this.staggerSlideOut('.radio-button')
        this.next()
        this.$nextTick(() => {
          this.staggerSlideIn('.radio-button')
        })
        this.freeze = false
      } else if (!initialAnswer && !this.id && this.$store.state.user.loggedIn) {
        this.saveResponse()
      }
    },
    async staggerSlideIn (selector) {
      await anime({
        targets: selector,
        translateX: [400, 0],
        opacity: [0, 100],
        delay: anime.stagger(100, { start: 200 }), // increase delay by 100ms for each elements.
        easing: 'spring(1, 100, 15, 0)'
      }).finished
    },
    async staggerSlideOut (selector) {
      /* let arr = document.querySelectorAll(selector)
      arr = [].slice.call(arr, 0).reverse() */
      await anime({
        targets: selector,
        translateX: [0, 400],
        opacity: [100, 0],
        delay: anime.stagger(100, { start: 100, direction: 'reverse' }), // increase delay by 100ms for each elements.
        easing: 'easeInCubic',
        duration: 250
      }).finished
    }
  }
}
</script>

<style lang="stylus" scoped>

nav
  justify-self flex-end
  display flex
  margin-top 2rem

nav > button
  min-width: 10rem;

nav > button:not(:last-child)
  margin-right 2rem

#content-area > div
  box-sizing: border-box;
  width 100%
  flex-grow 1
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding: 0 3rem;
</style>
