<template>
  <div class="range">
      <label class="radio-button" :style="`color: ${color}`">
        <span class="radio__input">
          <input type="radio" id="radio-1" name="range-value" value="5" v-model="picked" :disabled="disabled">
          <span class="radio__control"></span>
        </span>
        <span class="radio__label" for="radio-1">{{labels[0]}}</span>
      </label>
      <label class="radio-button" :style="`color: ${color}`">
        <span class="radio__input">
          <input type="radio" id="radio-2" name="range-value" value="4" v-model="picked" :disabled="disabled">
          <span class="radio__control"></span>
        </span>
        <span class="radio__label" for="radio-2">{{labels[1]}}</span>
      </label>
      <label class="radio-button" :style="`color: ${color}`">
        <span class="radio__input">
          <input type="radio" id="radio-3" name="range-value" value="3" v-model="picked" :disabled="disabled">
          <span class="radio__control"></span>
        </span>
        <span class="radio__label" for="radio-3">{{labels[2]}}</span>
      </label>
      <label class="radio-button" :style="`color: ${color}`">
        <span class="radio__input">
          <input type="radio" id="radio-4" name="range-value" value="2" v-model="picked" :disabled="disabled">
          <span class="radio__control"></span>
        </span>
        <span class="radio__label" for="radio-4">{{labels[3]}}</span>
      </label>
      <label class="radio-button" :style="`color: ${color}`">
        <span class="radio__input">
          <input type="radio" id="radio-5" name="range-value" value="1" v-model="picked" :disabled="disabled">
          <span class="radio__control"></span>
        </span>
        <span class="radio__label" for="radio-5">{{labels[4]}}</span>
      </label>
    </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    labels: {
      type: Array,
      default () {
        return ['I meget høj grad', 'I høj grad', 'I nogen grad', 'I ringe grad', 'I meget ringe grad']
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'orange'
    }
  },
  emits: ['answerChanged'],
  computed: {
    picked: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('answerChanged', value)
      }
    }
  },
  mounted () {
    anime.remove('.range')
    const el = document.querySelector('.range')
    anime({
      targets: el,
      opacity: [0, 1],
      easing: 'linear',
      duration: 700
    })
  },
  methods: {}
}
</script>

<style lang="stylus" scoped>
.range
  display flex
  justify-content space-between
  align-items flex-start
  opacity: 0;

*,
*:before,
*:after {
  box-sizing: border-box;
}
.radio-button {
  display flex
  flex-flow column
  align-items center
  width 3.5rem
  font-size: 40px;
  color: #333;
}

.radio__label
  font-size 12px
  color: #333;

.radio__input {
  margin-bottom: 8px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:focus + .radio__control {
      box-shadow: 0 0 6px rgba(0,0,0,.8)
    }
  }
}

.radio__control {
  display: grid;
  place-items: center;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 2px solid #333;
}

input + .radio__control::before {
  content: "";
  width: .5em;
  height: .5em;
  box-shadow: inset .5em .5em currentColor;
  border-radius: 50%;
  transition: 100ms transform ease-in-out;
  transform: scale(0);
}
input:checked + .radio__control::before {
  transform: scale(1.2);
}
</style>
